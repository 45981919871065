import { getAllowedTags } from "./mediaActions";

export const SET_IS_MEDIA_PICKER = "SET_IS_MEDIA_PICKER";
export function setIsMediaPicker(isMediaPicker) {
    return function (dispatch) {
        dispatch({
            type: SET_IS_MEDIA_PICKER,
            payload: isMediaPicker,
        });
    };
}

export const SET_MEDIA_TYPE_CONFIG = "SET_MEDIA_TYPE_CONFIG";
export function setMediaTypeConfig(config) {
    return function (dispatch) {
        dispatch({
            type: SET_MEDIA_TYPE_CONFIG,
            payload: config,
        });
    };
}

export const RESET_MEDIA_LIBRARY_STATE = "RESET_MEDIA_LIBRARY_STATE";
export function resetMediaLibraryState() {
    return {
        type: RESET_MEDIA_LIBRARY_STATE,
    };
}

export const SET_SELECTION_MODE = "SET_SELECTION_MODE";
export function setSelectionMode(mode) {
    return function (dispatch) {
        dispatch({
            type: SET_SELECTION_MODE,
            payload: mode,
        });
    };
}

export const SET_LOADING = "SET_LOADING";
export function setLoading(isLoading) {
    return function (dispatch) {
        dispatch({
            type: SET_LOADING,
            payload: isLoading,
        });
    };
}

export const SET_CURRENT_FOLDER = "SET_CURRENT_FOLDER";
export function setCurrentFolder(folder) {
    return function (dispatch) {
        dispatch({
            type: SET_CURRENT_FOLDER,
            payload: folder,
        });
    };
}

export const SET_MEDIA_IN_FOLDER = "SET_MEDIA_IN_FOLDER";
export function setMediaInFolder(media) {
    return function (dispatch) {
        dispatch({
            type: SET_MEDIA_IN_FOLDER,
            payload: media,
        });
    };
}

export const SET_SUB_FOLDERS = "SET_SUB_FOLDERS";
export function setSubFolders(folders) {
    return function (dispatch) {
        dispatch({
            type: SET_SUB_FOLDERS,
            payload: folders,
        });
    };
}

export const SET_SELECTED_MEDIA = "SET_SELECTED_MEDIA";
export const setSelectedMedia = (mediaOrUpdater) => (dispatch, getState) => {
    const currentSelectedMedia = getState().mediaLibrary.selectedMedia;
    const newSelectedMedia =
        typeof mediaOrUpdater === "function" ? mediaOrUpdater(currentSelectedMedia) : mediaOrUpdater;

    dispatch({
        type: SET_SELECTED_MEDIA,
        payload: newSelectedMedia,
    });
};

export const SET_SELECTED_FOLDERS = "SET_SELECTED_FOLDERS";
export const setSelectedFolders = (foldersOrUpdater) => (dispatch, getState) => {
    const currentSelectedFolders = getState().mediaLibrary.selectedFolders;
    const newSelectedFolders =
        typeof foldersOrUpdater === "function" ? foldersOrUpdater(currentSelectedFolders) : foldersOrUpdater;

    dispatch({
        type: SET_SELECTED_FOLDERS,
        payload: newSelectedFolders,
    });
};

export const SET_PAGINATION = "SET_PAGINATION";
export function setPagination(skip, take) {
    return function (dispatch) {
        dispatch({
            type: SET_PAGINATION,
            payload: { skip, take },
        });
    };
}

export const SCROLL_MEDIA_LIST = "SCROLL_MEDIA_LIST";
export const scrollMediaList = () => ({
    type: SCROLL_MEDIA_LIST,
});

export const SET_SHOW_MEDIA_DETAILS = "SET_SHOW_MEDIA_DETAILS";
export const setShowMediaDetails = (show) => ({
    type: SET_SHOW_MEDIA_DETAILS,
    payload: show,
});

export const SET_ALLOWED_TAGS = "SET_ALLOWED_TAGS";
export const setAllowedTags = (tags) => ({
    type: SET_ALLOWED_TAGS,
    payload: tags
});

export const loadAllowedTags = () => async (dispatch, getState) => {
    const { isAllowedTagsLoaded } = getState().mediaLibrary;

    if (!isAllowedTagsLoaded) {
        const tags = await dispatch(getAllowedTags());
        dispatch(setAllowedTags(tags));
    }
};