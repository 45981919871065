import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Breadcrumbs = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(["dynamicTranslation"]);

    const pages = useSelector((state) => state.pages);
    const identity = useSelector((state) => state.identity);
    const { currentPage } = pages;

    const handleNavigation = (route) => {
        if (window.navigate) {
            window.navigate(route);
        } else {
            navigate(route);
        }
    };

    return (
        <span
            style={{
                backgroundColor: "transparent",
                color: "white",
                marginLeft: 55,
                fontSize: "small",
            }}
        >
            {currentPage.breadcrumbs && currentPage.breadcrumbs.length > 0 && identity.user.Username && (
                <span style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                    <span style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        <div
                            onClick={() => handleNavigation("/")}
                            style={{ cursor: "pointer" }}
                            role="button"
                            tabIndex={0}
                        >
                            <span>
                                <HomeIcon fontSize="small" />
                            </span>
                        </div>

                        {currentPage.breadcrumbs.map((crumb, index) => {
                            if (crumb)
                                return (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            if (index === currentPage.breadcrumbs.length - 1) {
                                                window.location.reload();
                                            } else if (crumb.route) {
                                                handleNavigation(crumb.route);
                                            }
                                        }}
                                        style={{
                                            cursor: "pointer",
                                            textDecoration: "none",
                                        }}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <span style={{ marginLeft: 3, marginRight: 3 }}>
                                            <span style={{ marginRight: 7 }}>{"/"}</span>
                                            {t(crumb.text)}
                                        </span>
                                    </div>
                                );
                            else return <React.Fragment key={index} />;
                        })}
                    </span>
                </span>
            )}
        </span>
    );
};

export default Breadcrumbs;
