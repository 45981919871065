import Config from "../../Config";
import { authHeader, authHeaderForAzure, handleResponse, translateResponse } from "../../helpers/requestHelpers";
import { clearCachePool } from "../settingActions";

const config = new Config();

export function fetchArticles() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/GET", requestOptions).then((response) => handleResponse(response));
        // .then((json) => dispatch(receiveArticles(json)));
    };
}

export function fetchArticleCategoriesByArticleId(articleId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(
            config.backendHost + "/Articles/getArticleCategoriesByArticleId/" + articleId,
            requestOptions
        ).then((response) => handleResponse(response));
    };
}

export function fetchAiMarketingText(prompt) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForAzure(),
        body: JSON.stringify({ prompt: prompt }),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.marketingModule}` + "/fetchAiMarketingText", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function fetchArticlesWithImage(guidFileName) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ guidFileName: guidFileName }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/GetArticlesWithImage", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function getArticlesWithShopHeaderPicture(guidFileName) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ guidFileName: guidFileName }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/getArticlesWithShopHeaderPicture", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function getArticlesWithShopTilePicture(guidFileName) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ guidFileName: guidFileName }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/getArticlesWithShopTilePicture", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export const CACHE_ARTICLES = "CACHE_ARTICLES";

export function cacheArticles(articleGroupId, articles) {
    return { type: CACHE_ARTICLES, payload: { articleGroupId, articles } };
}

export function getArticlesForOrderManagementByArticleGroupsId(articleGroupId) {
    return function (dispatch, getState) {
        const cachedArticles = getState().orderManagement.cachedArticles;
        if (cachedArticles[articleGroupId]) {
            return Promise.resolve(cachedArticles[articleGroupId]);
        }

        const requestOptions = {
            method: "GET",
            headers: authHeader(),
        };
        return fetch(`${config.backendHost}/Articles/getArticlesByArticleGroupsId/${articleGroupId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                dispatch(cacheArticles(articleGroupId, json));
                return json;
            });
    };
}

export function fetchAllArticleNameAndId(loadOptions, t, language) {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    loadOptions.language = language;
    let query = new URLSearchParams(loadOptions).toString();
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.coreModule}/getAllArticleNameAndId?${query}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((response) => translateResponse(response, t));
        //.then(json => dispatch(receiveArticles(json)));
    };
}

export function toggleWebshopEnabled(articlesId) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/toggleWebshopEnabled", {
            method: "post",
            headers: authHeader(),
            body: JSON.stringify(articlesId),
        }).then((response) => handleResponse(response));
    };
}

export function toggleCookingTerminalEnabled(articlesId) {
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/toggleCookingTerminalEnabled", {
            method: "post",
            headers: authHeader(),
            body: JSON.stringify(articlesId),
        }).then((response) => handleResponse(response));
    };
}

export const RECEIVE_ARTICLES = "RECEIVE_ARTICLES";
export function receiveArticles(json) {
    return {
        type: RECEIVE_ARTICLES,
        articles: json.data,
    };
}

export function fetchArticlesByArticleGroup(articleGroupsId, loadOptions = null) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForAzure(),
        body: JSON.stringify({ articleGroupsId: articleGroupsId }),
    };
    let query = null;

    if (loadOptions) {
        query = new URLSearchParams(loadOptions).toString();
    }

    return function (dispatch) {
        if (loadOptions) {
            return fetch(`${config.apiUrl}/${config.coreModule}/getDummyListByArticleGroupsId?${query}`, requestOptions)
                .then((response) => handleResponse(response))
                .then((json) => {
                    dispatch(receiveArticlesByArticleGroup(json.data));
                    return json;
                });
        } else {
            return fetch(`${config.apiUrl}/${config.coreModule}/getDummyListByArticleGroupsId`, requestOptions)
                .then((response) => handleResponse(response))
                .then((json) => {
                    dispatch(receiveArticlesByArticleGroup(json.data));
                    return json;
                });
        }
    };
}

export const RECEIVE_ARTICLESBYARTICLEGROUP = "RECEIVE_ARTICLESBYARTICLEGROUP";
export function receiveArticlesByArticleGroup(json) {
    return {
        type: RECEIVE_ARTICLESBYARTICLEGROUP,
        articlesByArticleGroup: json,
    };
}

export function deleteArticle(articlesId, values, articleGroupId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId, article: { deleted: values } }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/update", requestOptions)
            .then((response) => handleResponse(response))
            .then((result) => dispatch(fetchArticlesByArticleGroup(articleGroupId)));
    };
}

export function setCookingProcessesForArticle(articlesId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/setCookingProcessesForArticle", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function postArticle(name, articleGroupsId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ name, articleGroupsId }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/AddNewArticle", requestOptions)
            .then((response) => handleResponse(response))
            .then((result) => {
                dispatch(fetchArticlesByArticleGroup(articleGroupsId));
                return result;
            });
    };
}

export function setArticleUID(articlesId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId }),
    };
    return function () {
        return fetch(config.backendHost + "/Articles/setArticleUID", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getArticleByName(articleName) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(articleName),
    };
    return function () {
        return fetch(config.backendHost + "/Articles/GetArticleByName", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => {
                return json;
            });
    };
}

export function setArticleGroupUID(key) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ key }),
    };
    return function () {
        return fetch(config.backendHost + "/ArticleGroups/setArticleGroupUID", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function updateArticle(key, values) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForAzure(),
        body: JSON.stringify({ articlesId: key, article: values }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/Update", requestOptions).then((response) => {
            dispatch(clearCachePool("Articles"));
            return handleResponse(response);
        });
    };
}

export function updateArticleCategories(key, values) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId: key, categories: values }),
    };
    return function () {
        return fetch(config.backendHost + "/Articles/UpdateArticleCategories", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function updateArticleDiets(key, values) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId: key, diets: values }),
    };
    return function () {
        return fetch(config.backendHost + "/Articles/UpdateArticleDiets", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function copyArticle(toCopyArticleId, newParentArticleGroup) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ toCopyArticleId: toCopyArticleId, newParentArticleGroup: newParentArticleGroup }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/CopyArticle", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function updateArticlePicture(articlesId = null, media = null) {
    const guidFileName = media ? media.guidFileName : "";
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId: articlesId, guidFileName: guidFileName }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/UpdatePicture", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export const REQUEST_ARTICLESWITHSAMENAME = "REQUEST_ARTICLESWITHSAMENAME";
export function requestArticlesWithSameName() {
    return {
        type: REQUEST_ARTICLESWITHSAMENAME,
    };
}

export const RECEIVE_LOGIN_TRANSLATIONS = "RECEIVE_LOGIN_TRANSLATIONS";
export function receiveLoginTranslations(json) {
    return {
        type: RECEIVE_LOGIN_TRANSLATIONS,
        loginTranslations: json,
    };
}

export const RECEIVE_ARTICLESWITHSAMENAME = "RECEIVE_ARTICLESWITHSAMENAME";
export function receiveArticlesWithSameName(json) {
    return {
        type: RECEIVE_ARTICLESWITHSAMENAME,
        articlesWithSameName: json,
    };
}

export function getArticlesWithSameName(articlesId) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForAzure(),
        body: JSON.stringify({ articlesId: articlesId }),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/${config.coreModule}/getArticlesWithSameName`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveArticlesWithSameName(json)));
    };
}

export function translateBios(languageCode) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ language: languageCode }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Settings/translateBios", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.error(err)
            )
            .then((json) => {
                return json;
            });
    };
}

export function translateText(key, value) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ key: key, value: value }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Settings/translateText", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.error(err)
            )
            .then((json) => {
                return json;
            });
    };
}

export function saveJsonFile(json, language) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ json: json, language: language }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Settings/saveJsonFile", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.error(err)
            )
            .then((json) => {
                return json;
            });
    };
}
export function loadTextsToTranslate() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Settings/loadTextsToTranslate", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.error(err)
            )
            .then((json) => {
                return json;
            });
    };
}

export function uploadDynamicTranslation() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Settings/uploadDynamicTranslation", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.error(err)
            )
            .then((json) => {
                return json;
            });
    };
}

export function dowloadDynamicTranslation() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Settings/dowloadDynamicTranslation", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.error(err)
            )
            .then((json) => {
                return json;
            });
    };
}

export function generateGermanJsons() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Settings/generateGermanJsons", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.error(err)
            )
            .then((json) => {
                return json;
            });
    };
}
export function updateShopHeaderPicture(articlesId = null, media = null) {
    const guidFileName = media ? media.guidFileName : "";
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId: articlesId, guidFileName: guidFileName }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/UpdateShopHeaderPicture", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function updateArticleIcons(articlesId = null, articleIcons = null) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId: articlesId, articleIcons: articleIcons }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/UpdateArticleIcons", requestOptions).then((response) => {
            handleResponse(response);
        });
    };
}

export function updateShopTilePicture(articlesId = null, media = null) {
    const guidFileName = media ? media.guidFileName : "";
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ articlesId: articlesId, guidFileName: guidFileName }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/UpdateShopTilePicture", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function addTranslationException(newException) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(newException),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Settings/addTranslationException", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.error(err)
            )
            .then((json) => {
                return json;
            });
    };
}

export function deleteTranslationException(translationExceptionsId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(translationExceptionsId),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Settings/deleteTranslationException", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.error(err)
            )
            .then((json) => {
                return json;
            });
    };
}

export function getAllTranslationExceptions() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Settings/getAllTranslationExceptions", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.error(err)
            )
            .then((json) => {
                return json;
            });
    };
}

export function loadLanguage(url) {
    let arr = url.split("/");
    let lng = arr[arr.length - 1];
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ key: lng }),
    };
    return function (dispatch) {
        return fetch(url, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.error(err)
            )
            .then((json) => {
                return json;
            });
    };
}

export function loadLoginTranslation(url) {
    let arr = url.split("/");
    let lng = arr[arr.length - 1];
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ key: lng }),
    };
    return function (dispatch) {
        return fetch(url, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.error(err)
            )
            .then((json) => dispatch(receiveLoginTranslations(json)));
    };
}

export function saveOnlineShopRecommendations(onlineShopRecommendations) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(onlineShopRecommendations),
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/onlineShop/SaveOnlineShopRecommendations", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => {
                return json;
            });
    };
}

export function getRecipesAndArticlesForOnlineShop() {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/onlineShop/GetRecipesAndArticlesForOnlineShop", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => {
                return json;
            });
    };
}
