import Config from "../Config";
import { authHeaderForFGFunctions, handleResponse, authHeaderUpload } from "../helpers/requestHelpers";
import store from "../store";

const config = new Config();

export function getMedia(guidFileName = null) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForFGFunctions(),
        body: JSON.stringify({ guidFileName: guidFileName }),
    };
    let requestURL = "/getMedia";
    return function () {
        return fetch(`${config.apiUrl}/${config.coreModule}` + requestURL, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}
export function getMediaByMediaFoldersId(mediaFolderId) {
    const mf = {
        mediaFolderId: mediaFolderId,
    };

    const requestOptions = {
        method: "POST",
        headers: authHeaderForFGFunctions(),
        body: JSON.stringify(mf),
    };

    return function () {
        return fetch(`${config.apiUrl}/${config.coreModule}` + "/getMediaByFoldersId", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function createMedia(formData) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderUpload(),
        body: formData,
    };
    return function () {
        return fetch(`${config.apiUrl}/${config.coreModule}` + "/createMedia", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function updateMedia(media) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForFGFunctions(),
        body: JSON.stringify(media),
    };
    return function () {
        return fetch(`${config.apiUrl}/${config.coreModule}` + "/updateMedia", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function deleteMedia(mediaId) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForFGFunctions(),
        body: JSON.stringify({ mediaId: mediaId }),
    };
    let requestURL = "/deleteMedia";
    return function () {
        return fetch(`${config.apiUrl}/${config.coreModule}` + requestURL, requestOptions).then((response) => handleResponse(response));
    };
}

export function getMediaContains(searchString) {
    let encodedSearchString = encodeURIComponent(searchString.toLowerCase());
    const requestOptions = {
        method: "GET",
        headers: authHeaderForFGFunctions(),
    };

    return function () {
        return fetch(`${config.apiUrl}/${config.coreModule}` + "/getMediaByString/" + encodedSearchString, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function getAltAndTitleByGuidFileName(guidFileName) {
    const requestOptions = {
        method: "POST",
        headers: authHeaderForFGFunctions(),
        body: JSON.stringify(guidFileName),
    };

    return function () {
        return fetch(`${config.apiUrl}/${config.coreModule}` + "/getAltAndTitleByGuidFileName", requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function getMediaByTag(tag) {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForFGFunctions(),
    };

    return function () {
        return fetch(`${config.apiUrl}/${config.coreModule}` + "/getMediaByTag/" + tag, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function getAllowedTags() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForFGFunctions(),
    };
    return function () {
        return fetch(`${config.apiUrl}/${config.coreModule}/getAllowedTags`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}
