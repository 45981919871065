import Config from "../../Config";
import { authHeader, authHeaderForAzure, handleResponse } from "../../helpers/requestHelpers";

const config = new Config();

export const REQUEST_ARTICLEPRICES = "REQUEST_ARTICLEPRICES";
export function requestArticlePrices() {
    return {
        type: REQUEST_ARTICLEPRICES,
    };
}

export const RECEIVE_ARTICLEPRICES = "RECEIVE_ARTICLEPRICES";
export function receiveArticlePrices(json) {
    return {
        type: RECEIVE_ARTICLEPRICES,
        articlePrices: json,
    };
}

export const REQUEST_ARTICLEGROUPARTICLEPRICES = "REQUEST_ARTICLEGROUPARTICLEPRICES";
export function requestArticleGroupArticlePrices() {
    return {
        type: REQUEST_ARTICLEGROUPARTICLEPRICES,
    };
}

export const RECEIVE_ARTICLEGROUPARTICLEPRICES = "RECEIVE_ARTICLEGROUPARTICLEPRICES";
export function receiveArticleGroupArticlePrices(json) {
    return {
        type: RECEIVE_ARTICLEGROUPARTICLEPRICES,
        articleGroupArticlePrices: json,
    };
}

export const REQUEST_ADDARTICLEPRICE = "REQUEST_ADDARTICLEPRICE";
export function requestAddArticlePrice() {
    return {
        type: REQUEST_ADDARTICLEPRICE,
    };
}

export const RECEIVE_ADDARTICLEPRICE = "RECEIVE_ADDARTICLEPRICE";
export function receiveAddArticlePrice() {
    return {
        type: RECEIVE_ADDARTICLEPRICE,
    };
}

/*Diese Funktion lädt alle Preise eines Artikels*/
export function fetchArticlePrices(articlesId) {
    return function (dispatch) {
        dispatch(requestArticlePrices());
        return fetch(`${config.apiUrl}/${config.coreModule}/getArticlePrices`, {
            method: "post",
            headers: authHeaderForAzure(),
            body: JSON.stringify({ articlesId }),
        })
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveArticlePrices(json)));
    };
}

/*Diese Funktion lädt alle Artikel einer Artikelgruppe und deren Preise*/
export function fetchArticleGroupArticlePrices(articleGroupsId) {
    return function (dispatch) {
        dispatch(requestArticleGroupArticlePrices());
        return fetch(config.backendHost + "/Prices/GetArticleGroupArticlePrices", {
            method: "post",
            headers: authHeader(),
            body: JSON.stringify(articleGroupsId),
        })
            .then((response) => handleResponse(response))
            .then((json) => dispatch(receiveArticleGroupArticlePrices(json)));
    };
}

/*Diese Funktion fügt einen neuen Preis für einen Artikel hinzu*/
export function addArticlePrice(articlesId, orderTypesId, priceGroupsId, sizesId, price) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
            articlesId,
            orderTypesId,
            priceGroupsId,
            sizesId,
            price,
        }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/AddPrice", requestOptions)
            .then((response) => handleResponse(response))
            .then((result) => dispatch(fetchArticlePrices(articlesId)));
    };
}

/*Diese Funktion fügt einen neuen Preis für einen Artikel direkt in der Tabelle hinzu*/
export function addArticlePriceFromList(articlesId, orderTypesId, priceGroupsId, sizesId, price) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
            articlesId,
            orderTypesId,
            priceGroupsId,
            sizesId,
            price,
        }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/AddPrice", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

/*Diese Funktion updated/löscht einen Artikelpreis*/
export function updateArticlePrice(pricesId, price, deleted) {
    if (!price) {
        price = 0;
    }
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({ pricesId, price, deleted }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Articles/UpdatePrice", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
