import {
    RESET_MEDIA_LIBRARY_STATE,
    SCROLL_MEDIA_LIST,
    SET_CURRENT_FOLDER,
    SET_IS_MEDIA_PICKER,
    SET_LOADING,
    SET_MEDIA_IN_FOLDER,
    SET_MEDIA_TYPE_CONFIG,
    SET_PAGINATION,
    SET_SELECTED_FOLDERS,
    SET_SELECTED_MEDIA,
    SET_SELECTION_MODE,
    SET_SHOW_MEDIA_DETAILS,
    SET_SUB_FOLDERS,
} from "../actions/mediaLibraryActions";

const initialState = {
    isMediaPicker: false,
    selectionMode: "singleSelect",
    isLoading: false,
    currentFolder: null,
    mediaInFolder: [],
    subFolders: [],
    selectedMedia: [],
    selectedFolders: [],
    paginationSkip: 0,
    paginationTake: 20,
    shouldScrollToTop: false,
    mediaTypeConfig: {
        allowImages: true,
        allowVideos: true,
    },
    showMediaDetails: false,
    allowedTags: [],
    isAllowedTagsLoaded: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_IS_MEDIA_PICKER:
            return {
                ...state,
                isMediaPicker: action.payload,
            };
        case SET_MEDIA_TYPE_CONFIG:
            return {
                ...state,
                mediaTypeConfig: action.payload,
            };
        case RESET_MEDIA_LIBRARY_STATE:
            return {
                ...initialState,
            };
        case SET_SELECTION_MODE:
            return {
                ...state,
                selectionMode: action.payload,
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case SET_CURRENT_FOLDER:
            return {
                ...state,
                currentFolder: action.payload,
            };
        case SET_MEDIA_IN_FOLDER:
            return {
                ...state,
                mediaInFolder: action.payload,
            };
        case SET_SUB_FOLDERS:
            return {
                ...state,
                subFolders: action.payload,
            };
        case SET_SELECTED_MEDIA:
            return {
                ...state,
                selectedMedia: action.payload,
            };
        case SET_SELECTED_FOLDERS:
            return {
                ...state,
                selectedFolders: action.payload,
            };
        case SET_PAGINATION:
            return {
                ...state,
                paginationSkip: action.payload.skip,
                paginationTake: action.payload.take,
                shouldScrollToTop: true,
            };
        case SCROLL_MEDIA_LIST:
            return {
                ...state,
                shouldScrollToTop: false,
            };
        case SET_SHOW_MEDIA_DETAILS:
            return {
                ...state,
                showMediaDetails: action.payload,
            };
        case 'SET_ALLOWED_TAGS':
            return {
                ...state,
                allowedTags: action.payload,
                isAllowedTagsLoaded: true
            };
        default:
            return state;
    }
};
